<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <div class="full-width">
                            <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">Form Management Library
                                <v-btn icon @click="resetData" dark>
                                    <v-icon small>fas fa-sync-alt</v-icon>
                                </v-btn>
                            </p>
                        </div>
                        <div class="full-width mb-5">
                            <new-form-button class="ml-6" form-type="simple"></new-form-button>
                        </div>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Forms
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="server_available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 clickable pointer" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" @click:row="editForm" :must-sort="true">
                <template v-slot:item.$id="{ item }">
                    <span class="nowrap">
                        <v-btn v-if="false" text small class="q_new_b_1--text  body-2" @click.stop="copyForm(item)">
                            <v-icon small>fas fa-copy</v-icon>
                        </v-btn>
                        <v-btn text small class="q_new_b_1--text  body-2" @click="editForm(item)">
                            <v-icon small>fas fa-pencil-alt</v-icon>
                        </v-btn>
                        
                        <v-btn v-if="item.FormType.toUpperCase() == 'SIMPLE'" text small class="q_new_b_1--text  body-2" @click.stop="viewForm(item)">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>
                        <v-btn v-if="item.FormType.toUpperCase() == 'QUIZ'" text small class="q_new_b_1--text  body-2" @click.stop="viewQuiz(item)">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>
                        <v-btn text small class="q_new_b_1--text  body-2" @click.stop="viewData(item)">
                            <v-icon small>fas fa-table</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{formatDate(item.created_at)}}
                </template>
                <!-- <template v-slot:body.append>
                    <tr>
                        <td colspan=10>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                </template> -->
            </v-data-table>
        </v-card>
    </div>
</template>
<script>
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import FormManagement from '@/store/Models/FormManagement'
import NewFormButton from './NewFormButton.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QFormDataTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
			entity: 'form_management',
            sync_dialog: false,
            //use this to "preset" a filter
            filters: {
                'EnabledInd_switch': true,
				'EditableInd_switch': true
            },
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            options: { sortBy: ['CreateDate'], sortDesc: [true] },
            server_available_filters: [],

        }
    },
    updated() {
        this.$nextTick(function() {
            if (this.$route.params.refresh) {
                this.$route.params.refresh = false
                this.refreshData(this.search)
            }
        })
    },
	mounted() {
	},
    computed: {
        filter_keys: function() {
            return FormManagement.state().filter_keys
        },
        all_data: function() {
            return FormManagement.query().orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc').get();
        },
        the_headers: function() {
            return [{
                    text: 'Title',
                    value: 'FormTitle',
                    align: 'start',
                    sortable: true,
                    class: 'header-class',
                },
                {
                    text: 'Slug',
                    value: 'Slug',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Form Type',
                    value: 'FormType',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Category',
                    value: 'FormCategory',
                    sortable: true,
                    filterable: true
                }, 
                {
                    text: 'Date Created',
                    value: 'CreateDate',
                    sortable: true,
                    filterable: true
                }, 
				{
                    text: '',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },
    },
    methods: {
		loadData: debounce(function(opt) {
			const g = this
			opt.needAvailableFilters = true
            QuilityAPI.getForms(g.filters, opt).then(function(json) {
                if (typeof json.meta != 'undefined' && typeof json.meta.filters != 'undefined') {
					g.server_available_filters = json.meta.filters
                }
				g.firstPull = false
            }).catch(function(err) {
                g.showError(err)
            })
            g.firstPull = false;
        }, 200),
        editForm: function(item) {
            this.$router.push({ name: 'FMLEditor', params: { 'form_id': item.ID } });
        },
        copyForm: function(item) {
            let copiedItem = item
            copiedItem.ID = null
            this.$router.push({ name: 'FMLEditor', params: { 'copy_form': copiedItem } })
        },
        viewForm: function(item) {
            this.$router.push({ name: 'FormView', params: { 'form_slug': item.Slug } });
        },
        viewQuiz: function(item) {
            this.$router.push({ name: 'QuizView', params: { 'form_slug': item.Slug } });
        },
        viewData: function(item) {
            this.$router.push({ name: 'FormData', params: { 'form_slug': item.Slug } });
        },
        customSort: function(items, index, isDesc) {
            items.sort((a, b) => {
                console.log(a)
                var nameA = a.title.toUpperCase(); // ignore upper and lowercase
                var nameB = b.title.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            return items;
        },
        resetData() {
            this.search = null
            this.filters.EnabledInd_switch = true
            this.filters.EditableInd_switch = true
            this.filters.FormCategory = "-All-"
			this.filters.FormType = "-All-"
			this.filters.LastChangeBy = "-All-"
            this.refreshData(this.search)
        }
    },
    watch: {

    },
    components: { NewFormButton }
}
</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>