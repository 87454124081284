<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <div class="full-width">
                            <p class="q-display-3 pl-7 pb-0 pt-0 mt-0 mb-0 white--text">CMS Content Pages
                                <v-btn icon @click="resetData" dark>
                                    <v-icon small>fas fa-sync-alt</v-icon>
                                </v-btn>
                            </p>
                        </div>
                        <div class="full-width mb-5">
                            <new-page-button class="ml-6" />
                        </div>
                        <p class="pt-0 mt-0 pl-5">
                            <v-btn text small color="q_leads_4" @click="toggleFilters">
                                <v-icon small left>fas fa-filter</v-icon> Filter Pages
                            </v-btn>
                        </p>
                    </v-col>
                    <v-col cols="9" sm="4" class="pr-7 mb-0">
                        <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details v-on:keyup="maybeSearch" @click:append="refreshData"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <!--</v-card-title>-->
            <v-row class="d-flex justify-start" v-if="toggleFilters">
                <q-filters dark ref="filters" :hide-button="true" v-model="filters" :available-filters="available_filters" :keys="filter_keys"></q-filters>
            </v-row>
            <v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 clickable pointer" :footer-props="{itemsPerPageOptions:[10,25,50,100]}" :loading="loading" :options.sync="options" :server-items-length="total_items" @click:row="editPage" :must-sort="true">
                <template v-slot:item.$id="{ item }">
                    <span class="nowrap">
                        <v-btn text small class="q_new_b_1--text  body-2" @click.stop="copyPage(item)">
                            <v-icon small>fas fa-copy</v-icon>
                        </v-btn>
                        <v-btn text small class="q_new_b_1--text  body-2" @click="editPage(item)">
                            <v-icon small>fas fa-pencil-alt</v-icon>
                        </v-btn>
                        <v-btn text small class="q_new_b_1--text  body-2" @click.stop="viewPage(item)">
                            <v-icon small>fas fa-eye</v-icon>
                        </v-btn>
                        <v-btn v-if="item.status !== 'trash'" text small class="red--text  body-2" @click.stop="confirmDeletePage(item)">
                            <v-icon small>fas fa-trash-alt</v-icon>
                        </v-btn>
                        <v-btn v-else text small class="red--text  body-2" @click.stop="confirmDeletePage(item)">
                            <v-icon small>fas fa-minus-circle</v-icon>
                        </v-btn>
                    </span>
                </template>
                <template v-slot:item.external_url="{ item }">
                    <v-btn v-if="item.external_url != '' && item.external_url != null" icon :href="item.external_url" target="_tab">
                        <v-icon small>fas fa-external-link-alt</v-icon>
                    </v-btn>
                </template>
                <template v-slot:item.created_at="{ item }">
                    {{formatDate(item.created_at)}}
                </template>
                <template v-slot:body.append>
                    <tr>
                        <td colspan=10>
                            <v-progress-linear color="primary" indeterminate v-if="loading"></v-progress-linear>
                        </td>
                    </tr>
                </template>
            </v-data-table>
        </v-card>
        <v-dialog v-model="dialogConfirmDelete" width="500">
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Move <span v-if="deletePagePermanently()">(PERMANENT)</span>"{{deletePageItemTitle}}" to the trash? </h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <p class="body">Are you sure you want to <strong v-if="deletePagePermanently()">PERMANENTLY</strong> remove this content? This will unpublish the page, move it to the trash and any links to it will no longer work.</p>
                    <p class="caption">*You can use "FILTER PAGES", STATUS / Trash to change the status back to Published or Draft in the Meta tab.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="ml-5" color="error" outlined @click="deletePage()">
                        Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5" color="error" depressed @click="dialogConfirmDelete = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import Page from '@/store/Models/Page'
import Category from '@/store/Models/Category'
import NewPageButton from './NewPageButton.vue'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'

export default {
    name: "QPageDataTable",
    mixins: [QDataTableMixin],
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'page',
            sync_dialog: false,
            //use this to "preset" a filter
            filters: {
                'exclude_zendesk_switch': true,
                'exclude_events_switch': true
            },
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            options: { sortBy: ['created_at'], sortDesc: [true] },
            dialogConfirmDelete: false,
            deletePageItem: null,
            deletePageItemTitle: ""
        }
    },
    updated() {
        this.$nextTick(function() {
            if (this.$route.params.refresh) {
                this.$route.params.refresh = false
                this.refreshData(this.search)
            }
        })
    },
    computed: {
        filter_keys: function() {
            return Page.state().filter_keys;
        },
        all_data: function() {
            return Page.query().orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc').get();
        },
        available_content_categories() {
            return Category.query().where('zendesk_category_id', function(v) {
                return v == 0
            }).get();
        },
        available_zendesk_categories() {
            return Category.query().where('zendesk_category_id', function(v) {
                return v > 0
            }).get();
        },
        the_headers: function() {
            return [{
                    text: 'Title',
                    value: 'title',
                    align: 'start',
                    sortable: true,
                    class: 'header-class',
                },
                {
                    text: 'Slug',
                    value: 'slug',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Date Created',
                    value: 'created_at',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Division',
                    value: 'division',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Category',
                    value: 'category_name',
                    sortable: false,
                    filterable: true
                },
                {
                    text: 'Last Change By',
                    value: 'last_change_by',
                    sortable: false,
                    filterable: true
                },
                {
                    text: 'External',
                    value: 'external_url',
                    sortable: true,
                    filterable: true
                }, {
                    text: '',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            Page.create({ data: [] })
            QuilityAPI.getPages(this.filters, opt);
            this.firstPull = false;
        }, 200),
        showSyncDialog: function() {
            this.sync_dialog = true;
            var g = this;
            setTimeout(function() { g.sync_dialog = false }, 4500);
        },
        editPage: function(item) {
            this.$router.push({ name: 'CMSEditor', params: { 'page_id': item.id } });
        },
        copyPage: function(item) {
            let copiedItem = item
            copiedItem.id = null
            this.$router.push({ name: 'CMSEditor', params: { 'copy_page': copiedItem } })
        },
        viewPage: function(item) {
            this.$router.push({ name: 'PageView', params: { 'page_slug': item.slug } });
        },
        confirmDeletePage: function(item) {
            this.deletePageItem = item
            this.deletePageItemTitle = item.title
            this.dialogConfirmDelete = true
        },
        deletePage: function() {
            var g = this
            var name = this.deletePageItem.title
            this.dialogConfirmDelete = false
            QuilityAPI.deletePage(this.deletePageItem.id).then(function() {
                //g.refreshData();
                g.showInfo("'" + this.deletePageItem.title + "' has been deleted. ")
            });
        },
        deletePagePermanently: function() {
            if (this.deletePageItem == null)
                return false
            return this.deletePageItem.status == 'trash'
        },
        customSort: function(items, index, isDesc) {
            items.sort((a, b) => {
                console.log(a)
                var nameA = a.title.toUpperCase(); // ignore upper and lowercase
                var nameB = b.title.toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            return items;
        },
        resetData() {
            this.search = null
            this.filters.exclude_zendesk_switch = true
            this.filters.exclude_events_switch = true
            this.filters.category_id = "-All-"
            this.filters.division = "-All-"
            this.filters.status = "-All-"
            this.refreshData(this.search)
        }
    },
    watch: {

    },
    components: { NewPageButton }
}

</script>
<style scoped>
.nowrap {
    white-space: nowrap;
}

</style>
