<template>
    <div>
        <v-card>
            <v-container fluid class="ma-0 pa-0 q_leads_1" :id="entity + '_datatable'">
                <v-row>
                    <v-col cols="3" sm="8" class="mb-0 pb-0">
                        <div class="full-width">
                            <p class="q-display-3 pl-7 pt-2 pb-3 mt-0 mb-0 white--text">CMS Categories
                                <v-btn icon @click="resetData" dark>
                                    <v-icon small>fas fa-sync-alt</v-icon>
                                </v-btn>
                            </p>
                            <template>
                                <div class="pb-3 ml-7 mb-5">
                                    <v-btn color="q_leads_2" dark @click="addNewCategory">
                                        <v-icon small left>fas fa-plus</v-icon> New Category
                                    </v-btn>
                                </div>
                            </template>
                        </div>
                    </v-col>
                </v-row>
            </v-container>

            <v-alert v-model="error" type="error" dismissible>
                <p v-for="message in errorMessages" :key="message">{{  message }}</p>
            </v-alert>

            <QDraggableList 
                v-model="draggableItems"
                :depth="1"
                color="q_blue_1"
            >
                <template #default="{ item, level }">
                    <div class="d-flex justify-space-between align-center" style="width: 100%">
                        <div>
                            {{ item.category }}
                        </div>
                        <div>
                            <QButton
                                v-if="level == 0"
                                type="text"
                                color="q_blue_1"
                                small
                                class="capitalize"
                            >
                                Add a sub category
                            </QButton>
                            <QButton
                                type="text"
                                color="q_blue_1"
                                small
                            >
                                <v-icon small>
                                    fas fa-pen
                                </v-icon>
                            </QButton>
                            <QButton
                                type="text"
                                color="error"
                                small
                            >
                                <v-icon>
                                    fas fa-trash
                                </v-icon>
                            </QButton>
                        </div>
                    </div>
                </template>
            </QDraggableList>
        </v-card>
        <!-- Update category -->
        <v-dialog v-model="updateCategoryDialog" width="50%" @keydown.esc="cancelCategory" @click:outside="cancelCategory">
            <v-card>
                <v-card-title>{{ updateCategoryTitle }}</v-card-title>
                <v-card-text v-if="updateCategoryDialog">
                    <v-form v-model="valid" ref="form" :disabled="saving">
                        <v-col cols="12" v-if="error">
                        <v-alert type="error">
                            {{ error }}
                        </v-alert>
                        </v-col>
                        <v-col cols="12" v-if="warning">
                            <v-alert type="warning">
                                {{ warning }}
                            </v-alert>
                        </v-col>
                        <v-row wrap>
                            <v-col cols="12">
                                <v-text-field v-model="currentCategory.category" label="Title" :rules="[form_rules.required]"></v-text-field>
                            </v-col>
							<v-col cols="12">
                                <v-checkbox v-model="currentCategory.zendesk_category_id" :false-value="0" label="Support Category?" />
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="currentCategory.description" label="Description"></v-text-field>
                            </v-col>
							<v-col cols="12" v-if="currentCategory.zendesk_category_id">
                                <v-select 
                                    v-model="currentCategory.parent_category_id" 
                                    :items="categoriesWithoutCurrent" 
                                    label="Parent category"
                                    item-text="category"
                                    item-value="id"
									clearable
                                />
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="ml-5 my-5" color="primary" @click="saveCategory">Save Changes</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5 my-5" color="error" depressed @click="cancelCategory">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="dialogConfirmDelete" width="500">
            <v-card>
                <v-card-title class="headline error" color="error">
                    <h4 class="q_white-text">Move <span v-if="deleteCategoryPermanently()">(PERMANENT)</span>"{{deleteCategoryItemTitle}}" to the trash? </h4>
                </v-card-title>
                <v-card-text class="pt-3">
                    <p class="body">Are you sure you want to <strong v-if="deleteCategoryPermanently()">PERMANENTLY</strong> remove this content? This will delete the category</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn class="ml-5" color="error" outlined @click="deleteCategory()">
                        Delete
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn class="mr-5" color="error" depressed @click="dialogConfirmDelete = false">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import QDataTableMixin from "@/components/datatables/QDataTableMixin"
import Category from '@/store/Models/Category'
import QuilityAPI from '@/store/API/QuilityAPI.js'
import { debounce } from 'vue-debounce'
import { QDraggableList, QButton } from '@/modules/shared'

const copy = (obj) => {
    return JSON.parse(JSON.stringify(obj))
}

const itemsToDraggable = (items) => {
    return copy(items)
        .sort((a, b) => a.order - b.order)
        .reduce((acc, item, index, items) => {
            if (item.parent_category_id) {
                const parent = items.find((i) => i.id === item.parent_category_id)
                if (parent) {
                    if (!parent.children) parent.children = []
                    parent.children.push(item)
                }
            } else {
                if (!item.children) item.children = []
                acc.push(item)
            }
            return acc
        }, [])
}

const draggableToItems = (items) => {
    const arr = []

    const deeplyPush = (item, order, parent = null) => {
        if (item.children) {
            item.children.forEach((child, index) => deeplyPush(child, index, item.id))
        }

        arr.push({
            ...item,
            parent_category_id: parent,
            order: order,
        })
    }

    items.forEach((item, index) => {
        deeplyPush(item, index)
    })

    return arr
}

const validateNewItems = (items) => {
    let errors = []
    items.forEach((topLevelCategory) => {
        if (!topLevelCategory.children) { return }

        const parents = topLevelCategory.children.filter((child) => child.children && child.children.length > 0)

        parents.forEach((parent) => {
            if (parent.children && parent.children.length > 0) {
                errors.push(`"${topLevelCategory.category}" can not contain "${parent.category}" with subcategories`)
            }
        })
    })
    return errors
}

export default {
    name: "QCategoriesDataTable",
    mixins: [QDataTableMixin],
    components: {
        QDraggableList,
        QButton
    },
    data: function() { // data internal to component (not available outside)
        return {
            entity: 'category',
            sync_dialog: true,
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load. 
            options: { sortBy: ['created_at'], sortDesc: [true] },
            category_id: null,
            updateCategoryDialog: false,
            updateCategoryTitle: '',
            currentCategory: {},
            dialogConfirmDelete: false,
            deleteCategoryItem: null,
            deleteCategoryItemTitle: "",
            warning: null,
            error: null,
            valid: false,
            saving: false,
            errorMessages: [],
        }
    },
    updated() {
        this.$nextTick(function() {
            if (this.$route.params.refresh) {
                this.$route.params.refresh = false
                this.refreshData(this.search)
            }
        })
    },
    computed: {
        all_data() {
            return Category.query().orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc').get();
        },
        draggableItems: {
            get() {
                return itemsToDraggable(this.all_data)
            },
            async set(items) {
                const newItems = draggableToItems(items)

                const errors = validateNewItems(newItems)
                if (errors.length > 0) {
                    this.errorMessages = errors
                    this.error = true
                    return
                }

                await Category.insertOrUpdate({
                    data: newItems
                })
            }
        },
        category() {
            return this.$store.state.entities.category.currentCategory;
        },
        the_headers: function() {
            return [{
                    text: 'Title',
                    value: 'category',
                    align: 'start',
                    sortable: true,
                    class: 'header-class',
                },
                {
                    text: 'Description',
                    value: 'description',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Support',
                    value: 'zendesk_category_id',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Action',
                    value: '$id',
                    sortable: false,
                    filterable: false
                }
            ]
        },

        categoriesWithoutCurrent() { 
            return this.all_data.filter((c) => {
                return c.id !== this.currentCategory.id
            })
        },
    },
    methods: {
        loadData: debounce(function(opt) {
            Category.create({ data: [] })
            QuilityAPI.getCategories(this.filters, opt);
            this.firstPull = false;
        }, 200),
        addNewCategory() {
            this.updateCategoryTitle = 'Add new category';
            this.currentCategory.id = null;
            this.currentCategory.category = '';
            this.currentCategory.description = '';
            this.currentCategory.zendesk_category_id = 0;
            this.currentCategory.parent_category_id = null;
            this.updateCategoryDialog = true;
        },
        showSyncDialog: function() {
            this.sync_dialog = true;
            let g = this;
            setTimeout(function() { g.sync_dialog = false }, 3000);
        },
        saveCategory () {
			const { supportValue, ...currentCategory } = this.currentCategory
			// we will change 'zendesk_category_id' if the initial value (0 (false) or any positive number (true)) has been changed
			// note: changed value - 'supportValue' - can be either 0 (false) or 1 (true)
			if (currentCategory.zendesk_category_id !== supportValue) {
				// we shouldn't change 'zendesk_category_id' if the initial and modified values were true (positive numbers)
				if (currentCategory.zendesk_category_id === 0 || supportValue === 0) {
					currentCategory.zendesk_category_id = supportValue
				}
			}
            if (!this.$refs.form.validate()) {
                this.warning = "Please correct invalid fields."
                setTimeout(() => { this.warning = null }, 5000)
                this.saving = false;
                return false;
            }
            if (typeof currentCategory.id === 'undefined' || currentCategory.id === null) {
                QuilityAPI.createCategory(currentCategory).then(() => {
                    this.hasChanges = false;
                    this.showInfo(`'${currentCategory.category}' has been created.`)
                })
                this.updateCategoryDialog = false
            } else {
                QuilityAPI.saveCategory(currentCategory).then(() => {
                    this.hasChanges = false
                    this.showInfo(`'${currentCategory.category}' has been updated.`)
                }).catch((err) => {
                    this.showError(`Error: ${err}`)
                    this.hasChanges = false
                })
                this.updateCategoryDialog = false
            }
        },
		cancelCategory () {
			this.updateCategoryDialog = false
			this.currentCategory = {}
		},
        editCategory (category) {
            this.updateCategoryTitle = 'Edit category'
			this.currentCategory = {
				...category,
				// we need this because 'zendesk_category_id' can be not only 0 and 1 but any positive number
				supportValue: category.zendesk_category_id > 0 ? 1 : 0
			}
            this.updateCategoryDialog = true
        },
        confirmDeleteCategory: function(item) {
            this.deleteCategoryItem = item
            this.deleteCategoryItemTitle = item.category
            this.dialogConfirmDelete = true
        },
        deleteCategory: function() {
            let g = this
            let name = this.deleteCategoryItem.category
            QuilityAPI.deleteCategory(this.deleteCategoryItem.id).then(function() {
                g.refreshData();
                g.showInfo("'" + name + "' has been deleted. ")
            });
            this.dialogConfirmDelete = false
        },
        deleteCategoryPermanently: function() {
            if (this.deleteCategoryItem == null)
                return false
            return this.deleteCategoryItem.status == 'trash'
        },
        resetData() {
            this.search = null
            this.filters.exclude_zendesk_support = true
            this.filters.status = "-All-"
            this.refreshData(this.search)
        }
    },

    watch: {
        all_data(newVal, oldVal) {
            newVal.forEach((item) => {
                const old = oldVal.find((oldItem) => oldItem.id === item.id)

                if (old) {
                    const isUpdated = Object.keys(item).some((key) => item[key] !== old[key])

                    if (isUpdated) {
                        QuilityAPI.saveCategory(item).then(() => {
                            this.showInfo("'" + item.category + "' has been updated. ")
                        })
                    }
                }
            })
        }
    }
}
</script>

<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>
