<template>
    <v-container fluid class="grey lighten-5 my-0 px-0  py-0">
        <!--<q-my-leads-submenu></q-my-leads-submenu>//-->
        <v-row class="mx-5">
            <v-col cols=12>
                <v-row class="pr-5">
                    <h2>Edit Navigation</h2>
                    <v-spacer />
                    <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn icon v-bind="attrs" v-on="on" @click="getNavItems">
                                <v-icon>fas fa-redo-alt</v-icon>
                            </v-btn>
                        </template>
                        <span>Revert to last saved version</span>
                    </v-tooltip>
                </v-row>
                <v-btn color="primary" @click="saveNavOrder">
                    Save Changes
                </v-btn>
                <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            </v-col>
        </v-row>
        <v-row class="mx-5">
            <v-col cols="12" sm="4" style="max-width:310px">
                <v-select :items="role_items" v-model="Role" label="Roles"></v-select>
            </v-col>
            <v-col cols="12" sm="4" style="max-width:310px">
                <v-select :items="division_items" v-model="Division" label="Division"></v-select>
            </v-col>
            <v-col cols="12" sm="4" style="max-width:310px">
                <v-select :items="permission_items" v-model="Permission" label="Permissions"></v-select>
            </v-col>
        </v-row>
        <v-row class="mx-5">
            <v-col cols=12 sm="6" md="5">
                <draggable tag="div" :list="nav_menu_items" class="v-list" :group="{ name: 'a' }">
                    <v-list-group v-for="(parent, ix) in nav_menu_items" :key="ix" class="elevation-2 mb-2 background-white" v-show="!parent.hidden">
                        <template v-slot:activator>
                            <v-list-item-icon>
                                <v-icon color="black">{{parent.icon}}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>
                                <v-icon color="grey lighten-2" class="pr-3">fas fa-arrows-alt</v-icon>
                                {{parent.label}}
                            </v-list-item-title>
                            <v-list-item-action class="wide-actions">
                                <v-btn icon @click.stop="editNavItem(ix)">
                                    <v-icon>fas fa-edit</v-icon>
                                </v-btn>
                                <v-btn icon @click.stop="deleteNavItem(ix)">
                                    <v-icon>fas fa-times</v-icon>
                                </v-btn>
                            </v-list-item-action>
                        </template>
                        <draggable tag="div" :list="parent.children" class="" :group="{ name: 'a' }">
                            <v-list-item v-for="(child, cix) in parent.children" :key="cix" class="ml-6 mb-1 background-white" v-show="!child.hidden">
                                <v-list-item-icon>
                                </v-list-item-icon>
                                <v-list-item-title>
                                    <v-icon color="grey lighten-2" class="pr-3">fas fa-arrows-alt</v-icon>
                                    {{child.label}}
                                </v-list-item-title>
                                <v-list-item-action class="wide-actions">
                                    <v-btn icon @click.stop="editNavItem(ix, cix)">
                                        <v-icon>fas fa-edit</v-icon>
                                    </v-btn>
                                    <v-btn icon @click.stop="deleteNavItem(ix, cix)">
                                        <v-icon>fas fa-times</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </draggable>
                        <div class="ml-11">
                            <v-tooltip right>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn icon depressed color="primary" class="ma-5" v-bind="attrs" v-on="on" @click="addNavItem(ix)">
                                        <v-icon>fas fa-plus-circle</v-icon>
                                    </v-btn>
                                </template>
                                <span>Add a nav item</span>
                            </v-tooltip>
                        </div>
                    </v-list-group>
                </draggable>
                <v-btn depressed color="primary" class="ma-5" @click="addNavItem()">Add Item</v-btn>
            </v-col>
        </v-row>
        <v-dialog v-model="show_edit_nav_item" width="50%">
            <q-edit-nav-menu-item v-on:close="show_edit_nav_item = false" v-on:saved="savedItem" :item="CurrentNavItem"></q-edit-nav-menu-item>
        </v-dialog>
        <!--<v-row class="mx-5">
            <pre>{{ nav_menu_items }}</pre>
        </v-row>//-->
        <v-snackbar v-model="notice.visible" top :color="notice.color" multi-line :timeout="6000">
            {{ notice.text }}
            <v-btn text @click="notice.visible = false">
                Close
            </v-btn>
        </v-snackbar>
    </v-container>
</template>
<script>
import draggable from "vuedraggable";
import QEditNavMenuItem from "./components/QEditNavMenuItem.vue";
import QuilityAPI from '@/store/API/QuilityAPI.js'

export default {
    props: ['id'],
    data() {
        return {
            loading: false,
            CurrentNavItem: {},
            Division: 'Any',
            Permission: 'Any',
            Role: 'Any',
            show_edit_nav_item: false,
            nav_menu_items: [],
        }
    },
    mounted: function() {
        this.getNavItems()
    },
    computed: {
        notice() {
            return this.$store.state.entities.page.notice;
        },
        division_items: function() {
            return [
                ...this.divisions,
                'Any'
            ]
        },
        role_items: function() {
            return [
                ...this.role_choices,
                'Any'
            ]
        },
        permission_items: function() {
            return [
                ...this.permission_choices,
                'Any'
            ]
        },
    },
    methods: {
        getNavItems() {
            var g = this
            this.loading = true
            QuilityAPI.getNavMenuItems().then(function(resp) {
                    if (typeof resp.data == 'undefined') {
                        g.showError(resp.msg)
                    } else {
                        g.$set(g, 'nav_menu_items', resp.data);
                        g.hideNavMenuItems();
                    }
                    g.loading = false;
                })
                .catch(function(err) {
                    g.showError(err.msg)
                    g.loading = false;
                });
        },
        hideNavMenuItems: function() {
            for (var i = 0; i < this.nav_menu_items.length; i++) {
                this.$set(this.nav_menu_items[i], 'hidden', this.hideItem(this.nav_menu_items[i]));
                for (var c = 0; c < this.nav_menu_items[i].children.length; c++) {
                    var h = this.hideItem(this.nav_menu_items[i].children[c])
                    this.$set(this.nav_menu_items[i].children[c], 'hidden', h);
                }
            }
        },
        hideItem: function(item) {
            var d_hide = false
            //hide based on division logic
            if (item.divisions.indexOf('Any') > -1) {
                d_hide = false;
            } else {
                if (this.Division == 'Any') {
                    d_hide = false;
                } else {
                    if (item.divisions.indexOf(this.Division) == -1) {
                        d_hide = true;
                    } else {
                        d_hide = false;
                    }
                }
            }

            //hide based on role logic
            var r_hide = false
            if (item.roles.indexOf('Any') > -1) {
                r_hide = false;
            } else {
                if (this.Role == 'Any' || item.roles.indexOf('Any') > -1) {
                    r_hide = false;
                } else {
                    if (item.roles.indexOf(this.Role) == -1) {
                        r_hide = true;
                    } else {
                        r_hide = false;
                    }
                }
            }

            //hide based on permission logic
            var p_hide = false
            if (item.permissions.indexOf('Any') > -1) {
                p_hide = false;
            } else {
                if (this.Permission == 'Any' || item.permissions.indexOf('Any') > -1) {
                    p_hide = false;
                } else {
                    if (item.permissions.indexOf(this.Permission) == -1) {
                        p_hide = true;
                    } else {
                        p_hide = false;
                    }
                }
            }
            return (d_hide || r_hide || p_hide);
        },
        editNavItem: function(ix, child_ix) {
            if (typeof child_ix != 'undefined') {
                //we need to set the index so that out save callback can update the correct item in the nav. 
                this.$set(this.nav_menu_items[ix].children[child_ix], 'child_ix', child_ix)
                this.$set(this.nav_menu_items[ix].children[child_ix], 'ix', ix)
                this.$set(this, 'CurrentNavItem', this.nav_menu_items[ix].children[child_ix])
            } else {
                //we need to set the index so that our save callback can update the correct item in the nav. 
                this.$set(this.nav_menu_items[ix], 'ix', ix)
                this.$set(this, 'CurrentNavItem', this.nav_menu_items[ix])
            }
            this.show_edit_nav_item = true
        },
        addNavItem: function(ix) {
            var item = {
                'id': null,
                'parent_id': null,
                'to': '',
                'href': null,
                'order': 9999999,
                'divisions': typeof ix != 'undefined' ? this.nav_menu_items[ix].divisions : [],
                'roles': typeof ix != 'undefined' ? this.nav_menu_items[ix].roles : ['Any'],
                'permissions': typeof ix != 'undefined' ? this.nav_menu_items[ix].permissions : ['Any'],
                'label': "New Menu Item",
            }
            if (typeof ix != 'undefined') {
                item.parent_id = this.nav_menu_items[ix].id
                this.nav_menu_items[ix].children.push(item)
                //we need to set the index so that out save callback can update the correct item in the nav. 
                item.ix = ix
                item.child_ix = this.nav_menu_items[ix].children.length - 1
            } else {
                this.nav_menu_items.push(item)
                //we need to set the index so that out save callback can update the correct item in the nav. 
                item.ix = this.nav_menu_items.length - 1
            }
            this.$set(this, 'CurrentNavItem', item)
            this.show_edit_nav_item = true
        },
        deleteNavItem: function(ix, child_ix) {
            var g = this
            if (typeof child_ix != 'undefined') {
                var id = this.nav_menu_items[ix].children[child_ix].id;
            } else {
                var id = this.nav_menu_items[ix].id;
            }
            this.loading = true
            QuilityAPI.deleteNavMenuItem(id).then(function(resp) {
                if (resp.success === true) {
                    if (typeof child_ix != 'undefined') {
                        g.nav_menu_items[ix].children.splice(child_ix, 1);
                    } else {
                        g.nav_menu_items.splice(ix, 1);
                    }
                    g.loading = false
                } else {
                    g.loading = false
                }
            });
        },
        savedItem: function(item) {
            this.show_edit_nav_item = false
            if (item.child_ix == null) {
                this.nav_menu_items[item.ix] = item;
            } else {
                this.nav_menu_items[item.ix].children[item.child_ix] = item;
            }
        },
        saveNavOrder: function() {
            var g = this
            //first we have to set the order parameter for each menu item. 
            for (var i = 0; i < this.nav_menu_items.length; i++) {
                this.nav_menu_items[i].order = i;
                if (typeof this.nav_menu_items[i].children != 'undefined') {
                    for (var c = 0; c < this.nav_menu_items[i].children.length; c++) {
                        this.nav_menu_items[i].children[c].order = i * 10 + c;
                    }
                }
            }
            this.loading = true
            QuilityAPI.reorderNavMenuItems({ 'data': this.nav_menu_items }).then(function(resp) {
                if (resp.success === false) {
                    g.loading = false
                    g.showError(resp.message)
                } else {
                    g.showSuccess("Saved Changes!")
                    g.$set(g, 'nav_menu_items', resp.data);
                    g.loading = false
                }
            });
        }
    },
    watch: {
        'Division': function() {
            this.$nextTick(this.hideNavMenuItems)
        },
        'Role': function() {
            this.$nextTick(this.hideNavMenuItems)
        },
        'Permission': function() {
            this.$nextTick(this.hideNavMenuItems)
        }
    },
    components: {
        draggable,
        QEditNavMenuItem
    }
}

</script>
<style scoped>
.background-white {
    background-color: #ffffff;
}

.wide-actions {
    width: 150px;
    display: inline-block;
}

</style>
